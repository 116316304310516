// .form-group{
//   display: flex;
// justify-content: space-between;
// }
//  h4 {
//   text-align: center;
//   font-size: 26px;
//   margin: 30px 0 -15px;
// }
// .form-control:focus {
//   border-color: $blue-primary;
// }
//  .form-control, .btn {
//   min-height: 40px;
//   border-radius: 3px;
// }
//
//
//
//  .btn,
//  .btn:active {
//   color: #fff;
//   border-radius: 4px;
//   background: $blue-primary !important;
//   text-decoration: none;
//   transition: all 0.4s;
//   line-height: normal;
//   border: none;
// }
//  .btn:hover,
//  .btn:focus {
//   background: $blue-primary !important;
//   outline: none;
// }
// .trigger-btn {
//   display: inline-block;
//   margin: 100px auto;
// }
//   input[type="submit"],
// ,
//   .input-md {
//     display: block !important;
//     font-family: segoe !important;
//     width: 100% !important;
//     height: calc(1.5em + 0.75rem + 2px) !important;
//     padding: 0.375rem 0.75rem !important;
//     font-size: 1rem !important;
//     font-weight: 400 !important;
//     line-height: 1.5 !important;
//     color: #495057 !important;
//     background-color: #fff !important;
//     background-clip: padding-box !important;
//     border: 1px solid #ced4da !important;
//     border-radius: 0.25rem !important;
//     transition: border-color 0.15s ease-in-out !important;
//     box-shadow: none !important;
//   }
//   input::placeholder {
//     font-size: 11px !important;
//   }

.auth-input {
  height: 60px;
border-radius: 10px !important;
text-align: center;
font-size: 25px !important;
&:focus{

border: 2px solid #1f3c908f;

box-shadow: 0px 1px 3px 2px #1f3c9069 !important;

font-weight: 400;
}
}
.card {
    // padding:5% 5% 3%;
    padding: 1% 2% 0%;
    margin-top: 15px;
    margin-bottom: 20px;
    border: none !important;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2)
}

.blue-text {
    color: #00BCD4
}

.form-control-label {
    margin-bottom: 0
}

input,
select,
textarea,
button {
    padding: 8px 15px;
    border-radius: 5px !important;
    margin: 5px 0px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    font-size: 18px !important;
    font-weight: 300
}

input:focus,select:focus,
textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #00BCD4;
    outline-width: 0;
    font-weight: 400
}

.btn-block {
    text-transform: uppercase;
    font-size: 15px !important;
    font-weight: 400;
    height: 43px;
    cursor: pointer
}

.btn-block:hover {
    color: #fff !important
}

button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0
}
button{
  background-color: $blue-primary;
}
