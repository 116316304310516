.registration-background {
  background: linear-gradient(240deg, #ffffff47 0%, #3b7de1e0 71%, #1d93d2 100%),
    url(../Assets/Logo/registration-bg.png), 0% 0% no-repeat padding-box;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.registration-top-bar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000005c;
  opacity: 1;
}
.registration-create-account-text {
  font: normal normal normal segoe;
  letter-spacing: 0px;
  color: #1d93d2;
  opacity: 1;
}
.registration-account-heading1 {
  text-align: left;
  font-size: 2rem;
  font-family: segoeBold;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
.registration-account-heading2 {
  text-align: left;
  font: normal normal normal 1.2rem segoe;
  letter-spacing: 0px;
  color: #f6f6f6;
  opacity: 1;
}
.registration-account-field-box {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent linear-gradient(180deg, #1d93d2 0%, #1d93d233 100%) 0%
    0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
}
.registration-btn {
  display: inline-block;
  font-weight: 400;
  color: white;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: $blue-primary;
  border: 1px solid $blue-primary;
  padding: 0.375rem 0.75rem;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100% !important;
}
.registration-app-store-positions {
  position: absolute;
  bottom: 3%;
  left: 0%;
}
.registration-checkbox {
  font-weight: 0.7rem;
}

//Media Quries
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .registration-background {
    height: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .registration-background {
    height: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .registration-background {
    height: 100%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .registration-background {
    height: 100vh;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
