.login-background {
  background: linear-gradient(240deg, #ffffff47 0%, #3b7de1e0 71%, #1d93d2 100%),
    url(../Assets/Logo/login-bg.png), 0% 0% no-repeat padding-box;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
}
.login-top-bar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000005c;
  opacity: 1;
}
.login-create-account-text {
  font: normal normal normal segoe;
  letter-spacing: 0px;
  color: #1d93d2;
  opacity: 1;
}
.login-account-heading1 {
  text-align: left;
  font-size: 2rem;
  font-family: segoeBold;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
.login-account-heading2 {
  text-align: left;
  font: normal normal normal 1.2rem segoe;
  letter-spacing: 0px;
  color: #f6f6f6;
  opacity: 1;
}
.login-account-field-box {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent linear-gradient(180deg, #1d93d2 0%, #1d93d233 100%) 0%
    0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
}
.login-btn {
  display: inline-block;
  font-weight: 400;
  color: white;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #1d77d2;
  border: 1px solid #1d77d2;
  padding: 0.375rem 0.75rem;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100% !important;
}
.login-app-store-positions {
  position: absolute;
  bottom: 3%;
  left: 0%;
}

.tooltiptext {
  position: relative;
  width: 120px;
  background-color: #1d77d2;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 10px 2rem 10px 2rem;

  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: #1d77d2 transparent transparent transparent;
}
.login-footer {
  background: transparent linear-gradient(358deg, #1d77d2 0%, #0f4a69 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px -4px 6px #00000029;
  opacity: 1;
  max-height: 100%;
}
.login-footer-img-appStore {
  width: 150px;
}
.login-footer-elements {
  border: 2px solid #ffffff;
  border-radius: 3px;
  opacity: 1;
  padding: 1px;
}
.login-footer-text-size {
  font-size: 0.6rem;
}
//Media Quries
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .login-background {
    height: 100%;
  }
  .login-footer {
    max-height: 100%;
  }
  .login-footer-img-appStore {
    width: 80px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .login-background {
    height: 100%;
  }
  .login-footer {
    max-height: 100%;
  }
  .login-footer-img-appStore {
    width: 100px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .login-background {
    height: 100%;
  }
  .login-footer {
    max-height: 100%;
  }
  .login-footer-img-appStore {
    width: 100px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .login-background {
    height: 90vh;
  }
  .login-footer {
    height: 100%;
  }
  .login-footer-img-appStore {
    width: 150px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
