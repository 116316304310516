//Tabs Start
//Active nav Link
.active-route {
  border-top: 5px solid #3498db !important;
  border-right: 0px none;
  background: #f8f9fa !important;
  color: $blue-primary !important;
}
//Active Nav link
.nav-logo {

  background: #1f3c90;
width: 67.4%;
height: 90px;
padding: 1%;
border-radius: 5px;
}
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}
.nav-tabs {
  border-bottom: none !important;
}
.icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: cover;
  //   text-align:;
}
.icon-report {
  background-image: url("../Assets/Navbar-Icons/reports.svg");
}

.icon-setting {
  background-image: url("../Assets/Navbar-Icons/setting.svg");
}
.icon-support {
  background-image: url("../Assets/Navbar-Icons/support.svg");
}
#pills li.active > a {
  background: red;
}

.nav-tabs > li {
  //   margin-right: 1px;
  height: 100%;
}
.nav-tabs > li > a {
  border-radius: 0px;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  //   border-right: 0px none;
  //   margin-right: 0px;
  //   padding: 8px 17px;
  //   color: #222222;
  transition: all 0.3s ease-in 0s;
}
.nav-tabs > li:last-child {
  border-right: none !important;
}
.nav-tabs > li > a {
  //   padding-left: 20px;
  //   padding-right: 20px;
  //   border: 1px solid #ededed;
  //   border-right: 0px none;
  border-top: 2px solid #ededed;
  //   background: #ededed;
  background-color: white;
  color: #8f8f8f;
  font-weight: bold;
}
.nav-tabs > li > a:hover {
  border-bottom-color: #ededed;
  border-right: 0px none;
  background: #f8f9fa;
  color: $blue-primary !important;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border-top: 5px solid #3498db !important;
  border-right: 0px none;
  background: #f8f9fa;
  color: $blue-primary !important;

  .icon-report {
    background-image: url("../Assets/Navbar-Icons/reports_hover.svg");
  }
  .icon-setting {
    background-image: url("../Assets/Navbar-Icons/setting_hover.svg");
  }
  .icon-support {
    background-image: url("../Assets/Navbar-Icons/support_hover.svg");
  }
}
.tab-content > .tab-pane {
  border: none !important;
  border-top: 0px none;
  padding: 20px;
  line-height: 22px;
}
.tab-content > .tab-pane > h3 {
  margin-top: 0;
}

//Tabs End
.country-flag {
  width: 30px;
}

//Media Quries
@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .nav-logo {
    width: 100px !important;
  }
  .nav-tabs > li > a {
    border-radius: 0px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    border: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    //   border-right: 0px none;
    //   margin-right: 0px;
    //   padding: 8px 17px;
    //   color: #222222;
    transition: all 0.3s ease-in 0s;
  }
}
@media (min-width: 768px){
.navbar-expand-lg .navbar-toggler {
    display: none;
}
}
@media (min-width: 768px){
.navbar-expand-lg .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
}
}
