.registrationNew-bg {
  background: linear-gradient(
      220.89deg,
      rgba(255, 255, 255, 0.278) -8.31%,
      rgba(255, 255, 255, 0.878) 68.27%,
      #ffffff 100%
    ),
    url(../Assets/Logo/registration-bg.png), 0% 0% no-repeat padding-box;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.registrationNew-fieldBox {
  background: linear-gradient(
    180deg,
    rgba(72, 137, 201, 0.522) 0%,
    rgba(255, 255, 255, 0) 95%
  );
  .registrationNew-HCP-panel {
    border-left: 5px solid black;
    background-color: white;
  }
  .registrationNew-welcome {
    // font-size: 2rem;
  }
  .registrationNew-register {
    font-size: 1rem;
  }
  .error-color-input {
    border-color: red !important;
  }
  .form-group {
    margin-bottom: 0.6rem;
  }
  .registrationNew-form-control {
    border-left: 5px solid $blue-primary !important;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .pass-wrapper {
    position: relative;

    i {
      position: absolute;
      top: 38% !important;
      right: 7% !important;
      color: gray;
    }
    i:hover {
      cursor: pointer;
    }
  }
  .registerNew-btn {
    display: inline-block;
    font-weight: 400;
    color: white;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #1d77d2;
    border: 1px solid #1d77d2;
    padding: 0.375rem 0.75rem;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    width: 100% !important;
  }
  .registerNew-login-btn {
    display: inline-block;
    font-weight: 400;
    color: white;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #288b39;
    border: 1px solid #288b39;
    padding: 0.375rem 0.75rem;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    width: 100% !important;
  }
  .registrationNew-terms {
    color: black;
  }
}
.registrationNew-footer-text {
  font-size: 0.7rem;
}
.registrationNew-heading-1 {
  color: $blue-primary;
}
.login-account-heading2 {
  color: $blue-primary;
}
// .registrationNew-footer {
//   height: 10vh;
// }
.footer-vertical-line {
  border-right: 2px solid #333;
  margin-right: 8px;
  margin-left: 8px;
}
//Media quries
@media only screen and (max-width: 641px) {
  .registrationNew-bg {
    background: linear-gradient(
        220.89deg,
        rgba(255, 255, 255, 0.278) -8.31%,
        rgba(255, 255, 255, 0.878) 68.27%,
        #ffffff 100%
      ),
      url(../Assets/Logo/login-bg.png), 0% 0% no-repeat padding-box;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .registrationNew-footer {
  }
}
@media only screen and (min-width: 1370px) {
  .registrationNew-fieldBox {
    .registrationNew-form-control {
      border-left: 5px solid $blue-primary !important;
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 10px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .form-group {
      margin-bottom: 1rem;
    }
  }
}
