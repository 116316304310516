//OverView start
.overview-border {
  border-left: 5px solid $blue-primary;
}
.report-filters {
  width: 70px;
  font-size: 0.7rem;
  padding: 0px 5px 0px 5px;
}
.position-sticky {
  position: sticky !important;
  top: 10% !important;
}

.dropbtn {
  i {
    color: black;
  }
  background-color: transparent !important;
  border: transparent !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-contenting {
  display: none;
  position: absolute;
  right: 20%;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

// .dropdown-content a:hover {
//   background-color: #ddd;
// }

// .dropdown:hover .dropdown-content {
//   display: block;
// }

// .dropdown:hover .dropbtn {
//   color: $blue-primary;
// }
//Overview End

//Time in average start

#timeInRange {
  width: 100%;
  height: 100%;
  font-size: 11px;
  border-right: 3px solid #d6d6d6;
}

//Time in average End

//Trend Start

.filter-menu-btn {
  background: linear-gradient(235.79deg, #7467f0 7.19%, #2896e9 92.84%);
  width: 100% !important;
  border-radius: 3px;
  padding: 6px 0px 6px 5px;
  border: transparent !important;
  font-size: 0.8rem;
  color: white;
  font-weight: bold;
  text-align: left;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;

  background-color: #f1f1f1;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  width: 100%;

  left: 0%;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  // margin-left: -80px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.border-left-orange {
  border-left: 5px solid $orange-primary;
  border-radius: 5px;
}
.border-left-green {
  border-left: 5px solid $green-primary;
  border-radius: 5px;
}
.border-left-black {
  border-left: 5px solid black;
  border-radius: 5px;
}

////Trend End

//Time in average start

#timeInRange {
  width: 100%;
  height: 100%;
  font-size: 11px;
  border-right: 3px solid #d6d6d6;
}

body,
html {
  height: 100% !important;
  scroll-behavior: smooth;
  position: relative;
}
//Media Quries
@media only screen and (max-width: 600px) {
  .report-filters {
    width: 40px;
    font-size: 0.7rem;
    padding: 0px 5px 0px 5px;
  }
  .overview-border {
    border-left: none;
  }
  .timeRange {
    min-height: 350px;
  }
}

// Overlay Page

// ----Switch button ----
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #17c11d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #17c11d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
// Compare Report
// //---Tabs---// //
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}
.compare > .tab {
  font-family: "Sora", sans-serif;
}
.compare > .tab .nav-tabs {
  background-color: transparent;
  border: none;
}
.compare > .tab .nav-tabs li a {
  color: #333;
  background: #f5f5f5;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  padding: 11px 15px 10px;
  margin: 0 10px 1px 0;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease 0s;
}
.compare > .compare.tab .nav-tabs li:last-child a {
  margin-right: 0;
}
.compare > .tab .nav-tabs li a:hover,
.compare > .tab .nav-tabs li.active a {
  color: #fff !important;
  background: #f5f5f5;
  border: none;
}
.compare > .tab .nav-tabs li a:before {
  content: "";
  background: $blue-primary;
  height: 100%;
  width: 100%;
  opacity: 0;
  transform: scale(0.5);
  position: absolute;
  left: 50%;
  top: 0;
  z-index: -1;
  transition: opacity 0.4s ease 0s, left 0.3s ease 0s, transform 0.4s ease 0.2s;
}
.compare > .tab .nav-tabs li.active a:before,
.compare > .tab .nav-tabs li a:hover:before {
  opacity: 1;
  transform: scale(1);
  left: 0;
}
.compare > .tab .tab-content {
  color: #333;
  // background: linear-gradient(to right bottom,#f5f5f5 50%, transparent 50%);
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 25px;
  padding: 20px;
  border-top: 4px solid $blue-primary;
  border-left: 4px solid $blue-primary;
  position: relative;
}
.compare > .tab .tab-content h3 {
  color: $blue-primary;
  font-size: 22px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 7px 0;
}
@media only screen and (max-width: 479px) {
  .compare > .tab .nav-tabs li {
    width: 100%;
  }
  .compare > .tab .nav-tabs li a {
    margin: 0 0 10px;
  }
  .compare > .tab .tab-content h3 {
    font-size: 18px;
  }
}
// ---vertical-line---
.vertical-line {
  border-left: 2px solid black;
}
// ---Statistics ---
.stats-font {
  font-size: 0.7rem;
}
.stats-font-small {
  font-size: 0.63rem;
}
.stats-reading {
  background-color: #1d77d2;
  color: white;
  font-size: 1rem;
  padding: 5px;
}
.fullCircle-very-high {
  width: 15px;
  height: 15px;
  border: 1px solid #1d71d2;
  border-radius: 50%;
  background-color: #1d71d2;
}
.fullCircle-in-range1 {
  width: 15px;
  height: 15px;
  border: 1px solid #4da0db;
  border-radius: 50%;
  background-color: #4da0db;
}
.fullCircle-in-range2 {
  width: 15px;
  height: 15px;
  border: 1px solid #77a9db;
  border-radius: 50%;
  background-color: #77a9db;
}
.fullCircle-in-range3 {
  width: 15px;
  height: 15px;
  border: 1px solid #1a6a95;
  border-radius: 50%;
  background-color: #1a6a95;
}
.fullCircle-in-range4 {
  width: 15px;
  height: 15px;
  border: 1px solid #87d3fc;
  border-radius: 50%;
  background-color: #87d3fc;
}
