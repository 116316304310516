//Text Color
.font-blue {
  color: $blue-primary;
}
.font-blue-dark {
  color: $blue-primary-dark;
}
.font-gray {
  color: $gray-primary;
}
.text-orange {
  color: #ff5801;
}
.font-size-small {
  font-size: 0.5rem;
}
.font-size-small-1 {
  font-size: 0.7rem;
}
.font-size-normal {
  font-size: 1rem;
}
.font-size1 {
  font-size: 1.5rem;
}
.font-size2 {
  font-size: 2rem;
}
.font-size2-half {
  font-size: 2.5rem;
}
.font-size3 {
  font-size: 3rem;
}
.font-size4 {
  font-size: 1.5rem !important;
}

a {
  list-style: none !important;
  color: inherit !important;
  & :focus {
    outline: none !important;
  }
}
button {
  border: none !important;
}
button:focus:not(:focus-visible) {
  outline: none !important;
  outline: 0px transparent !important;
}
input:focus {
  outline: 0 !important;
}
button:focus {
  outline: 0;
}
button :focus {
  // outline: none !important;
  outline: 0px transparent !important;
}
.bg-filter-btn:focus {
  outline: 0 !important;

  color: white !important;
  background-color: $blue-primary !important;
  border: 1px solid $blue-primary !important;
  border-radius: 20px !important;
  padding: 8px 25px 8px 25px !important;
  font-size: 10px !important;
}
.bg-filter-btn {
  color: $blue-primary !important;
  background-color: white !important;
  border: 1px solid $blue-primary !important;
  border-radius: 22px !important;
  // padding-left: 25px !important;
  // padding-right: 25px !important;
  font-size: 10px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  width: 120px;
  height: 43px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0d92d233;
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .bg-filter-btn {
    color: $blue-primary !important;
    background-color: white !important;
    border-color: $blue-primary !important;
    border-radius: 20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
@media (min-width: 576px){
.modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
}
}
.pdf-device{
  .col-3{
    padding: 2%;
  }
  button{
    margin: 3%;
    width: 90%;
    height: 100%;
    background: lightblue;
  }
}

.printing-screen {
  display: flex;
  margin: auto;
  // width: 80%;
}

@media print {
  .top-bar{
    display: none !important;
  }
  .side-bar{
    display: none!important;
  }
  .print-btn {
    display: none;
  }
  .printing-screen {
    display: flex;
    width: 100%;
    margin: none;
  }
  .print-test {
    height: 750px!important;
  }
  .print-head {
    height: 100vh!important;
    align-items: center;
    justify-content: center;
  }
}
.pdf-img {
  width: 40px;
}
.bg-gray {
  background-color: $gray-primary;
}
.pdf-btn{
  background: none;
    position: absolute;
    z-index: 1;
    left: 91%;
    top: 35px;
    // @media screen and (min-width: 1400px) and (max-width:2000px){
    //   top: 1%;
    // }
    // @media screen and (max-width:767px){
    //   left: 75%;
    //   top: 3.5%;
    // }
}
.unit{
  font-size: 1rem !important;
}
.text-black {
  color: black;
}
.bg-transparent {
  background: transparent;
}
.ant-menu {
  background: transparent;
    border: none;
    color: white;
}
.anticon {
  margin-right: 6%;
}
.settings {
  p {
    width: 100%;
  }
  label {
    display: flex;

    select {
      margin-left: auto;
      width: 100px;
      text-align: center;
    }
  }
}
.session-table{
  th{
    max-width: 70px;
    word-break: break-all;
  }
}
.bg-common {
  background: #1F3C90;
}
.logo-gen {
  padding: 1%;
    background: #1f3c90;

}
.norm{
  width: 100%;
  min-height: 400px;
}
.spec{
  width: 100%;
  min-height: 400px;
}
.loading {
  min-height: 375px;
  margin: auto;
  border-radius: 50px;
  background: #1f3c90;
  p{
    color: white;
    font-weight: bolder;

  }
}
.feedback-btn {
  display: block;
max-width: fit-content;
position: fixed;
bottom: 0;
right: 2%;
background: #1f3c90;
    padding: 5px 1%;
    color: white !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    p{
      padding-top: 3px;
    }
}
.h-7550 {
  height: 7550px;
}
.sticky-top {

    z-index: 1041;
}
// @media print {
//   @page { size:  auto; margin: 50px; }
//
// }
