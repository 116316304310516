$blue-primary: #1f3c90;
$blue-primary-dark: #215b94;
$purple-primary: #ad3860;
$gray-primary: #818181;
$dark-gray: #6d6d6d;
$orange-primary: #ff5801;
$green-primary: #757205;

$navbar-bg-blue: #003650;
$navbar-text-color: #0d92d2;
$navbar-btn-color-blue: #086e9e;

$top-navbar-text-color: #b3b3b3;
$top-navbar-link1-color: #0d92d2;
$top-navbar-link2-color: #0d92d2;
$top-navbar-link3-color: #e80643;
$top-navbar-link4-color: #ad3860;
$top-navbar-link5-color: #0db5cb;
$top-navbar-link6-color: #7b54c4;
$top-navbar-link7-color: #a5d20d;
$top-navbar-link8-color: #21ad5d;
$top-navbar-link9-color: #eb7612;
$top-navbar-link10-color: #dd2161;
$top-navbar-link11-color: #be3ee5;
